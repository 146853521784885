import React, { Component, PropTypes } from 'react';
import moment from 'moment'; // @see https://www.npmjs.com/package/moment

// import action
import { pushPlace, pushCourse, pushPeriod } from '../action.es6';

// import properties
import { LOCALE_DAY_OF_WEEK } from '../properties.es6';

moment.locale('ja');
moment.updateLocale('ja', { weekdays: LOCALE_DAY_OF_WEEK });

/**
 *  コンテナ
 *  @version 2017/01/05
 *  @author ryo-as
 */
export default ({ event, error, dispatch }) => {
  const { choices, inputs } = event;

  return (
    <div>
      {/* 場所 */}
      <Place choices={choices.places} input={inputs.place} error={error.messages.place} {...{ dispatch }} />
      {/* コース */}
      <Course choices={choices.courses} input={inputs.course} error={error.messages.course} {...{ dispatch }} />
      {/* 日時 */}
      <Period choices={choices.periods} input={inputs.period} error={error.messages.period} {...{ dispatch }} />
    </div>
  );
};

/**
 *  場所
 *  @version 2017/01/05
 *  @author ryo-as
 */
const Place = ({ choices, input, error, dispatch }) => {
  return (
    <div className='p-entry-form__item'>
      <div className='p-entry-form__caption'>ご希望の教室</div>
      <span className='p-entry-form__required'>必須</span>
      <div className='p-entry-form__detail'>
        <div className='c-form-field'>
          <div className='c-form-errorText p-entry-form__errorText'>{error}</div>
          {Object.keys(choices).map((place, key) => {
            return (
              <label className='c-form-radioLabel p-entry-form__parts--checkbox' {...{ key }}>
                <input
                  className='c-form-radio'
                  type='radio'
                  name='place'
                  value={place}
                  checked={Object.is(input, place)}
                  onClick={(event) => dispatch(pushPlace(event.target.value))}
                />
                <i className='c-form-radioIcon'></i>
                {choices[place]}校
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

/**
 *  コース
 *  @version 2017/01/05
 *  @author ryo-as
 */
const Course = ({ choices, input, error, dispatch }) => {
  try {
    return (
      <div className='p-entry-form__item'>
        <div className='p-entry-form__caption'>ご希望のコース</div>
        <span className='p-entry-form__required'>必須</span>
        <div className='p-entry-form__detail'>
          <div className='c-form-field'>
            <div className='c-form-errorText p-entry-form__errorText'>{error}</div>
            {choices.map((course, key) => {
              return (
                <label className='c-form-radioLabel p-entry-form__parts--checkbox' {...{ key }}>
                  <input
                    className='c-form-radio'
                    type='radio'
                    name='course'
                    value={course.code}
                    checked={Object.is(input, course.code)}
                    onClick={(event) => dispatch(pushCourse(event.target.value))}
                  />
                  <i className='c-form-radioIcon'></i>
                  {course.name}コース
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  } catch (error) {
    return (
      <div className='p-entry-form__item'>
        <div className='p-entry-form__caption'>ご希望のコース</div>
        <span className='p-entry-form__required'>必須</span>
        <div className='p-entry-form__detail'>
          <div className='c-form-field'>教室を選択してください</div>
        </div>
      </div>
    );
  }
};

/**
 *  日時
 *  @version 2017/01/05
 *  @author ryo-as
 */
const Period = ({ choices, input, error, dispatch }) => {
  try {
    return (
      <div className='p-entry-form__item'>
        <div className='p-entry-form__caption'>ご希望の日時</div>
        <span className='p-entry-form__required'>必須</span>
        <div className='p-entry-form__detail'>
          <div className='c-form-field'>
            <div className='c-form-errorText p-entry-form__errorText'>{error}</div>
            <label className='c-form-selectLabel p-entry-form__parts--event'>
              <select
                className='c-form-select c-form-select--long'
                name='event_id'
                value={input}
                onChange={(event) => dispatch(pushPeriod(event.target.value))}
              >
                <option value=''>日時を選択してください</option>
                {choices.map((event, key) => {
                  return (
                    <option value={event.id} {...{ key }}>
                      {moment(event.start_at).format('YYYY年MM月DD日(dddd) HH:mm')} 〜{' '}
                      {moment(event.end_at).format('HH:mm')}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    return (
      <div className='p-entry-form__item'>
        <div className='p-entry-form__caption'>ご希望の日時</div>
        <span className='p-entry-form__required'>必須</span>
        <div className='p-entry-form__detail'>
          <div className='c-form-field'>コースを選択してください</div>
        </div>
      </div>
    );
  }
};
