// import modules.
import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// import view component.
import Container from './container.es6';
// import reducer.
import reducer from './reducer.es6';
// import action
import { initForm, initEvent } from './action.es6';

/**
 * Register Middleware
 * @const {object}
 */
const middleware = [thunk];

/**
 * Create Store
 * @const {object}
 */
const store = createStore(reducer, applyMiddleware(...middleware));

/**
 *  Panels Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
export default class Contact extends React.Component {
  /**
   *  初期描画直後に一度だけ実行
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  UNSAFE_componentWillMount() {
    const { master, defaults, token, device } = this.props;

    const inputs = {
      place: defaults.place,
      course: defaults.course,
      period: defaults.event_id,
    };

    store.dispatch(initForm({ ...{ defaults, token, device } }));
    store.dispatch(initEvent({ ...{ master, inputs } }));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    return (
      <Provider store={store}>
        <Container />
      </Provider>
    );
  }
}
