// import modules
import React, { Component, PropTypes } from 'react';
import serialize from 'form-serialize'; // @see https://www.npmjs.com/package/form-serialize
import { connect } from 'react-redux';

// import action
import { validate } from './action.es6';

/**
 *  Container Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class Container extends Component {
  /**
   *  アップデート前に実行
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  componentWillUpdate(nextProps) {
    if (nextProps.error.valid) this.refs.form.submit();
  }

  /**
   *  コンストラクタ
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  validate(event) {
    // submit処理を抑制
    event.preventDefault();
    // フォームデータ取得
    this.props.dispatch(validate(serialize(this.refs.form, { hash: true, empty: true })));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    const { error, form } = this.props;

    return (
      <form method='post' className='p-contact-form' ref='form'>
        {/* お名前 */}
        <div className={`c-form-field ${error.messages.name ? 'c-form-field-error' : ''} p-contact-form__field`}>
          <p className='c-form-label p-contact-form__label'>お名前</p>
          <span className='c-form-require p-contact-form__require'>必須</span>
          <input
            className='c-form-text p-contact-form__text'
            name='contact[name]'
            placeholder='お名前を入力してください'
            type='text'
          />
        </div>

        {/* メールアドレス */}
        <div className={`c-form-field ${error.messages.email ? 'c-form-field-error' : ''} p-contact-form__field`}>
          <p className='c-form-label p-contact-form__label'>メールアドレス</p>
          <span className='c-form-require p-contact-form__require'>必須</span>
          <input
            className='c-form-text p-contact-form__text'
            name='contact[email]'
            placeholder='メールアドレスを入力してください'
            type='text'
          />
        </div>

        {/* 件名 */}
        <div className={`c-form-field ${error.messages.subject ? 'c-form-field-error' : ''} p-contact-form__field`}>
          <p className='c-form-label p-contact-form__label'>件名</p>
          <span className='c-form-require p-contact-form__require p-contact-form__require--optional'>任意</span>
          <input
            className='c-form-text p-contact-form__text'
            name='contact[title]'
            placeholder='件名を入力してください'
            type='text'
          />
        </div>

        {/* お問い合わせ内容 */}
        <div
          className={`c-form-field ${error.messages.contact ? 'c-form-field-error' : ''} p-contact-form__field p-contact-form__field--textarea`}
        >
          <p className='c-form-label p-contact-form__label p-contact-form__label--textarea'>お問い合わせ内容</p>
          <span className='c-form-require p-contact-form__require p-contact-form__require--textarea'>必須</span>
          <textarea
            className='c-form-textArea p-contact-form__textarea'
            name='contact[content]'
            placeholder='お問い合わせ内容を入力してください'
            type='text'
          />
        </div>

        {/* プライバシーポリシー */}
        <div
          className={`c-form-field ${error.messages.check ? 'c-form-field-error' : ''} p-contact-form__field p-contact-form__field--privacyPolicy`}
        >
          <label className='c-form-checkboxLabel'>
            <input className='c-form-checkbox' name='check' type='checkbox' value='on' />
            <i className='c-form-checkboxIcon'></i>
          </label>
          <p className='p-contact-form__privacyPolicy'>
            <a href='/privacy' target='_blank' className='u-link'>
              プライバシーポリシー
            </a>
            に同意する
          </p>
        </div>

        {error.valid === false ? (
          <div className='p-contact-form__warning'>
            <div className='c-form-warning'>未入力項目があります</div>
          </div>
        ) : null}

        <div className='p-contact-form__field p-contact-form__field--button'>
          <button className='c-btnMain-primaryB p-contact-form__button' onClick={::this.validate}>
            送信する
          </button>
        </div>

        <input type='hidden' name='authenticity_token' value={form.token} />
      </form>
    );
  }
}

/**
 * State to Props
 * @const {object}
 */
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Container);
