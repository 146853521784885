import $ from 'jquery';

// ページ遷移後にURLにparamsがあるとそこまでスクロール
(() => {
  // ヘッダーのセレクター
  const SELECTOR_HEAD = '.l-fixedHeader';
  // スクロールスピード
  const SPEED = 500;

  /**
   * GETパラメータ展開
   */
  const purseGetParams = (string) => {
    // 変数用意
    const params = {};
    // 文字列展開
    string.split('&').forEach((param) => {
      // プロパティ展開
      const prop = param.split('=');
      // 挿入
      params[prop[0]] = prop[1];
    });
    // 返却
    return params;
  };

  window.addEventListener('load', () => {
    const object = document.querySelector(SELECTOR_HEAD);

    if (!object) return false;

    const offset = object.offsetHeight;
    const params = purseGetParams(window.location.search.replace('?', ''));

    // scroll値がない場合は終了
    if (!params.scroll) return false;

    // ポジションを算出
    const position = $(`#${params.scroll}`).offset().top - offset;

    // スクロール実行
    $('html, body').animate({ scrollTop: position }, SPEED, 'swing');

    return false;
  });
})();
