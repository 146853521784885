// import modules
import $ from 'jquery';
import React, { Component, PropTypes } from 'react';
import serialize from 'form-serialize'; // @see https://www.npmjs.com/package/form-serialize
import { connect } from 'react-redux';

// import action
import { validate } from './action.es6';

// import properties
import { GRADES, OPPORTUNITIES, LOCALE_DAY_OF_WEEK } from './properties.es6';

import Event from './components/event.jsx';

/**
 *  Container Class
 *  @version 2016/05/02
 *  @author ryo.aso
 */
class Container extends Component {
  /**
   *  アップデート前に実行
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  componentWillUpdate(nextProps) {
    // 初回にスクロールしないように
    if (nextProps.error.valid === null) return;

    // エラーがなかったらフォームを送信
    if (nextProps.error.valid) return this.refs.form.submit();

    // エラーの場合はフォームの一番上にスクロールする
    $('html, body').animate({ scrollTop: $('#entry-form').offset().top });
  }

  /**
   *  コンストラクタ
   *  @version 2017/05/12
   *  @author ayana.kusu
   */
  validate(event) {
    // submit処理を抑制
    event.preventDefault();
    // フォームデータ取得
    this.props.dispatch(validate(serialize(this.refs.form, { hash: true, empty: true })));
  }

  /**
   *  表示処理
   *  @version 2016/05/02
   *  @author ryo.aso
   */
  render() {
    const { form, event, error, dispatch } = this.props;

    return (
      <div className='p-entry-form'>
        <form ref='form' method='post' action='/entry/confirm'>
          <div className='p-entry-form__inner'>
            <Event {...{ event, error, dispatch }} />

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>保護者様氏名</div>
              <span className='p-entry-form__required'>必須</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.parent_name}</div>
                  <input
                    className='c-form-text p-entry-form__parts--name'
                    placeholder='例）コード花子'
                    type='text'
                    name='parent_name'
                    defaultValue={form.defaults.parent_name}
                  />
                </div>
              </div>
            </div>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>お電話番号</div>
              <span className='p-entry-form__required'>必須</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.tel}</div>
                  <input
                    className='c-form-text p-entry-form__parts--tel'
                    placeholder='例）09012345678'
                    type='tel'
                    name='tel'
                    defaultValue={form.defaults.tel}
                  />
                </div>
              </div>
            </div>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>メールアドレス</div>
              <span className='p-entry-form__required'>必須</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.mail}</div>
                  <input
                    className='c-form-text c-form-text--long p-entry-form__parts--email'
                    type='email'
                    name='mail'
                    defaultValue={form.defaults.mail}
                    placeholder='例）example@codecamp.jp'
                  />
                </div>
              </div>
            </div>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>
                CodeCampKIDSを{form.device == 'pc' ? <br /> : null}知ったきっかけ
              </div>
              <span className='p-entry-form__required p-entry-form__required--optional'>任意</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.oppotunity}</div>
                  <label className='c-form-selectLabel p-entry-form__parts--oppotunity'>
                    <select className='c-form-select' name='oppotunity' defaultValue={form.defaults.oppotunity}>
                      <option value=''>選択してください</option>
                      {OPPORTUNITIES.map((value, key) => {
                        return <option {...{ value, key }}>{value}</option>;
                      })}
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <p className='p-entry-form__childHeading'>参加されるお子様について教えてください</p>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>氏名</div>
              <span className='p-entry-form__required'>必須</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.child_name}</div>
                  <input
                    className='c-form-text p-entry-form__parts--name'
                    placeholder='例）コード太郎'
                    type='text'
                    name='child_name'
                    defaultValue={form.defaults.child_name}
                  />
                </div>
              </div>
            </div>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>ふりがな</div>
              <span className='p-entry-form__required'>必須</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.child_kana}</div>
                  <input
                    className='c-form-text p-entry-form__parts--name'
                    placeholder='例）こーどたろう'
                    type='text'
                    name='child_kana'
                    defaultValue={form.defaults.child_kana}
                  />
                </div>
              </div>
            </div>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>学年</div>
              <span className='p-entry-form__required'>必須</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.grade}</div>
                  <label className='c-form-selectLabel p-entry-form__parts--grade'>
                    <select className='c-form-select' name='grade' defaultValue={form.defaults.grade}>
                      <option value=''>選択してください</option>
                      {GRADES.map((value, key) => {
                        return <option {...{ value, key }}>{value}</option>;
                      })}
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div className='p-entry-form__item'>
              <div className='p-entry-form__caption'>備考</div>
              <span className='p-entry-form__required p-entry-form__required--optional'>任意</span>
              <div className='p-entry-form__detail'>
                <div className='c-form-field'>
                  <div className='c-form-errorText p-entry-form__errorText'>{error.messages.note}</div>
                  <textarea
                    className='c-form-textArea'
                    placeholder='例）保護者2名と弟1名で付き添います。'
                    name='note'
                    defaultValue={form.defaults.note}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className='p-entry-form__item'>
              ※体験授業へのお申し込みは体験会にご参加いただくお子さま1名につき1件でのお申し込みをお願い致します。付き添いの方が複数人いらっしゃる際は備考欄に人数をご記入ください。
            </div>
          </div>

          <div className='p-entry-form__detail'>
            <div className='c-form-field'>
              <div className='c-form-errorText p-entry-form__errorText'>{error.messages.check}</div>
              <div className='p-entry-form__parts--check'>
                <label className='c-form-checkboxLabel'>
                  <input className='c-form-checkbox' name='check' type='checkbox' value='consent' />
                  <i className='c-form-checkboxIcon'></i>
                </label>
                <span className='p-entry-form__required p-entry-form__required--checkbox'>必須</span>
                <div className='p-entry-form__textLink'>
                  <a href='/privacy' target='_blank' className='u-link'>
                    プライバシーポリシー
                  </a>
                  、{form.device == 'sp' ? <br /> : null}
                  <a href='/rule' target='_blank' className='u-link'>
                    利用規約
                  </a>
                  に同意する
                </div>
              </div>
            </div>
          </div>

          <nav className='p-entry-form__submitWrap'>
            <input type='hidden' name='authenticity_token' value={form.token} />
            <button
              className={`c-btnMain-primaryA c-btn-radius ${form.device == 'pc' ? 'c-btnSize-64' : 'c-btnSize-40'} p-entry-form__submit`}
              onClick={::this.validate}
            >
              確認画面へ進む
            </button>
          </nav>
        </form>
      </div>
    );
  }
}

/**
 * State to Props
 * @const {object}
 */
const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Container);
